<template>
    <div v-if="form.theme" id="add" class="widget" :class="themeClass">
        <!--v-if="form.theme"-->
        <div class="container">
            <h2 class="widget__title">Испытайте удачу</h2>
            <div class="widget__descr">Получите случайный подарок при записи на сервис</div>
            <div class="widget__list swiper">
                <div class="swiper-wrapper">
                    <div class="widget__item swiper-slide" v-for="(item, index) in filteredItems" :key="index"
                         :class="{active:item.isActive}">
                        <div class="widget__item-content">
                            <div class="widget__image">
                                <img :src="item.img"/>
                            </div>
                            <div class="widget__subtitle">{{ item.name }}</div>
                        </div>
                    </div>
                </div>
                <div class="widget__frame"></div>

            </div>

            <transition name="dialog">
                <button v-if="isButtonVisible" class="widget__button" @click="onRun" :disabled="isRunStarted">
                    <span>Испытать удачу</span>
                </button>
            </transition>

            <transition name="dialog">
                <div v-if="isDialogVisible" class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-container">
                            <button
                                type="button"
                                class="modal-close"
                                @click="onDialogClose"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 72 72"
                                     id="emoji">
                                    <g id="color"/>
                                    <g id="hair"/>
                                    <g id="skin"/>
                                    <g id="skin-shadow"/>
                                    <g id="line">
                                        <line x1="17.5" x2="54.5" y1="17.5" y2="54.5" fill="none" stroke="#000000"
                                              stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
                                              stroke-width="2"/>
                                        <line x1="54.5" x2="17.5" y1="17.5" y2="54.5" fill="none" stroke="#000000"
                                              stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
                                              stroke-width="2"/>
                                    </g>
                                </svg>
                            </button>
                            <div>
                            <h3 class="modal-header">
                                Поздравляем, Вы выиграли подарок <br> "{{ activeItem.name }}"!
                            </h3>

                            <form @submit.prevent="submit">
                                <input v-model="form.name" type="text" placeholder="Имя">
                                <input v-model="form.phone" type="text"
                                       placeholder="Телефон">
                                <p v-if="error" v-html="error"></p>
                                <button type="submit" class="modal-submit">
                                    <pulse-loader v-if="isLoading" :loading="true" color="#fff" size="5px"/>
                                    <span v-else>Записаться</span>
                                </button>
                            </form>
                                </div>

                        </div>
                    </div>
                </div>
            </transition>

        </div>
    </div>
</template>

<script>
import Vue from "vue";
import {Swiper} from 'swiper';
import 'swiper/swiper.scss';
import axios from "axios";
import Config from "@/config";
import PulseLoader from 'vue-spinner/src/PulseLoader';

import VueMask from 'v-mask' ;

Vue.use(VueMask);

let swiper;

export default {
    components: {PulseLoader},
    data() {
        return {
            isRunFinished: false,
            isRunStarted: false,
            isModalOpened: false,
            items: [
                {
                    id: 1,
                    img: require('@/assets/1.svg'),
                    name: 'Проверка развал/схождения'
                },
                {
                    id: 2,
                    img: require('@/assets/2.svg'),
                    name: 'Антидождь'
                },
                {
                    id: 3,
                    img: require('@/assets/3.svg'),
                    name: 'Мойка двигателя'
                },
                {
                    id: 4,
                    img: require('@/assets/4.svg'),
                    name: 'Скидка 50% на антикор'
                },
                {
                    id: 5,
                    img: require('@/assets/5.svg'),
                    name: 'Антибактериальная обработка'
                },
                {
                    id: 6,
                    img: require('@/assets/6.svg'),
                    name: 'Смазка замков и уплотнителей'
                },
                {
                    id: 7,
                    img: require('@/assets/7.svg'),
                    name: 'Регулировка углов установки колес'
                },
                {
                    id: 8,
                    img: require('@/assets/8.svg'),
                    name: 'Мойка ДВС'
                },
                {
                    id: 9,
                    img: require('@/assets/9.svg'),
                    name: 'Мойка Люкс'
                },
                {
                    id: 10,
                    img: require('@/assets/10.svg'),
                    name: 'Промо ТО'
                },
                {
                    id: 11,
                    img: require('@/assets/11.svg'),
                    name: 'Озонирование салона'
                },
                {
                    id: 12,
                    img: require('@/assets/12.svg'),
                    name: 'Замена колодок за 1 руб.'
                },
                {
                    id: 13,
                    img: require('@/assets/13.svg'),
                    name: 'Замена масла в ДВС'
                },
                {
                    id: 14,
                    img: require('@/assets/14.svg'),
                    name: '1000 руб. на ТО'
                },
                {
                    id: 15,
                    img: require('@/assets/15.svg'),
                    name: 'Проверка УУК'
                },
                {
                    id: 16,
                    img: require('@/assets/16.svg'),
                    name: 'Номерные рамки'
                },
                {
                    id: 17,
                    img: require('@/assets/17.svg'),
                    name: 'Мойка а/м'
                },
            ],
            filteredItems: null,
            isButtonVisible: true,
            isDialogVisible: false,
            isSubmitted: false,
            form: {
                name: "",
                phone: "",
                theme: "",
                service: "",
            },
            error: "",
            isLoading: false
        }
    },
    computed: {
        themeClass() {
            const c = [];
            if (this.isRunFinished) c.push("finished");
            if (this.$route.query?.theme) c.push(this.$route.query?.theme);
            return c;
        },
        activeItem() {
            return this.filteredItems.find(el => el.isActive);
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        initWidget() {
            // init theme
            this.form.theme = this.$route.query?.theme;
            // init theme items
            const ids = this.$route.query?.ids?.split(',');
            this.filteredItems = this.items.filter(item => ids?.some(id => +id === item.id));
        },
        onRun() {
            if (this.isSubmitted) {
                this.isDialogVisible = true;
                return;
            }
            this.isRunStarted = true;

            const min = 10;
            const max = 30;

            // reset isActive in all items
            this.isRunFinished = false;
            this.filteredItems.forEach(el => Vue.set(el, "isActive", false));

            const countSwipes = min + Math.floor(Math.random() * (max - min));
            //swiper.slideToLoop(3, 3000, false);

            let i = 0;
            const repeatSwipes = () => {
                swiper.slideNext(300, false);
                i++;
                if (i >= countSwipes) {
                    // we've done!
                    // place any ending routines here

                    const activeIndex = swiper.realIndex;
                    //console.log("ACTIVE INDEX: ", activeIndex);
                    this.filteredItems[activeIndex].isActive = true;

                    setTimeout(() => {
                        this.isRunFinished = true;
                        this.isButtonVisible = false;
                    }, 350);

                    setTimeout(() => {
                        this.isDialogVisible = true;
                        this.form.service = this.filteredItems[activeIndex];

                    }, 3000);

                    return;
                }
                ;
                setTimeout(() => {
                    repeatSwipes();
                }, 350);
            }

            repeatSwipes();
        },
        onDialogClose() {
            this.isDialogVisible = false;
            this.isButtonVisible = true;
            this.isRunStarted = false;
        },
        submit() {
            const name = /^.{2,}$/;
            if (!name.test(String(this.form.name).toLowerCase())) {
                return this.error = "Пожалуйста, введите Ваше имя";
            }

            const phone = this.form.phone.replace(/\D/, "");
            const rePhone = /^\d{11}$/;
            if (!rePhone.test(phone)) {
                return this.error = "Пожалуйста, введите Ваш номер телефона";
            }

            this.error = false;
            this.isLoading = true;

            return axios.post(Config.FORM_URL, this.form)
                .then(res => {
                    if (res.error) {
                        console.error("ERROR", res);
                        if (res.data?.error) {
                            if (res.data.code === Config.ERROR_CODE_EXISTS) this.error = "Такой номер телефона уже зарегистрирован.<br />Регистрация возможна только один раз."
                            else if (res.data.code === Config.ERROR_CODE_WRONG_PARAMS) this.error = "Отправлены неверные параметры.<br />Пожалуйста, проверьте и попробуйте снова."
                            else this.error = "Произошла неизвестная ошибка.<br />Пожалуйста, попробуйте отправить еще раз.";

                        }
                    } else {
                        this.isSubmitted = true;
                    }
                })
                .catch(error => {
                    this.error = error;
                    console.error("ERROR", error);
                })
                .finally(res => {
                    this.isLoading = false;
                    this.isButtonVisible = true;
                    this.isDialogVisible = false;
                });
        }
    },
    created() {
        this.initWidget();
    },
    mounted() {
        swiper = new Swiper(".widget__list", {
            slidesPerView: 1,
            spaceBetween: 0,
            direction: "horizontal",
            centeredSlides: true,
            loop: true,
            allowTouchMove: false,
            freeMode: true,
            speed: 500,
            freeModeMomentum: false,

            breakpoints: {
                500: {
                    slidesPerView: 2.5,
                    spaceBetween: 0,
                },
                600: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
                750: {
                    slidesPerView: 3.5,
                    spaceBetween: 0,
                },
                850: {
                    slidesPerView: 3.7,
                    spaceBetween: 0,
                },
                950: {
                    slidesPerView: 3.9,
                    spaceBetween: 0,
                },
                1050: {
                    slidesPerView: 5,
                    spaceBetween: 0,
                },
            },
        });
    },
}
</script>

<style lang="scss">
body {
    color: black;
    background-color: transparent;
    padding-top: 50px;
}

ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

/*.container {

    width: 100%;
    padding: 21px;
    margin-right: auto;
    margin-left: auto;
    z-index: 2;
    max-width: 732.1875px;

    @media (min-width: 781px) {
        max-width: 960.9375px;
    }
    @media (min-width: 1025px){
        max-width: 1200px;
    }

}*/


.widget {

    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & input {
            border-radius: 0;
            max-width: 225px;
            width: 100%;
            font-size: 16px;
            padding: 0 16px;
            height: 52px;
            outline: none;
            color: #000;
            background-color: transparent;
            border: 1px solid #000;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            @media only screen and (min-width: 420px) {
                max-width: 318px;
            }

            @media only screen and (min-width: 600px) {
                max-width: 426px;
            }
        }

        p {
            margin: 0 0 15px 0;
        }
    }

    .modal-close {
        position: absolute;
        right: -8px;
        top: -8px;
        padding: 10px;
        background: transparent;
        cursor: pointer;
        margin: 0 auto;
        display: block;
        border: none;
        outline: none;
        @media only screen and (min-width: 420px) {
            right: 0;
            top: -7px;
        }
        @media only screen and (min-width: 600px) {
            right: 10px;
            top: 10px;
        }
    }

    .modal-submit {
        cursor: pointer;
        background-color: #000;
        text-align: center;
        font-size: 13px;
        color: #ffffff;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        padding: 18px 44px;
        font-weight: 700;
        transition: 0.2s;
        display: inline-block;
        border: none;
        outline: none;
        width: 100%;
    }

    .modal-mask {
        position: fixed;
        z-index: 3;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        max-width: 280px;
        width: 100%;
        margin: 0 auto;
        padding: 25px 15px;
        background-color: #fff;
        transition: all 0.3s ease;
        position: relative;

        @media only screen and (min-width: 420px) {
            max-width: 350px;
            padding: 30px;
        }
        @media only screen and (min-width: 600px) {
            max-width: 460px;
            padding: 50px;
        }
    }

    .modal-header {
        margin: 0 0 30px 0;
        text-align: center;

    }

    .modal-body {
        margin: 20px 0;
    }

    .modal-default-button {
        float: right;
    }

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }


    &.finished {
        .widget__item.swiper-slide:not(.active):not(.swiper-slide-active) {
            opacity: 0.2;
        }

        .widget__item.swiper-slide.active,
        .widget__item.swiper-slide.swiper-slide-active {
            animation: animSlide 2000ms forwards;
            animation-delay: 500ms;
        }

    }

    &.kia {
        font-family: KiaSignature, sans-serif !important;

        .modal-submit {
            border-radius: 3px;
            position: relative;

            & span {
                &:after {
                    position: absolute;
                    content: "";
                    bottom: 13px;
                    width: 70%;
                    left: 15%;
                    right: 0;
                    border-bottom: 1px solid;
                    transform: scaleX(0);
                    transform-origin: left center;
                    transition: transform .25s ease-out;
                    will-change: transform;
                    pointer-events: none;
                }

                &:hover {
                    &:after {
                        transform: scaleX(1);
                    }
                }
            }
        }

        .widget {
            &__title {
                font-size: 28px !important;
                line-height: 32px;

                @media (min-width: 1025px) {
                    font-size: 38px;
                    line-height: 48px;
                }
            }

            &__subtitle {
                font-size: 16px;
                line-height: 23px;
            }

            &__button {
                border-radius: 3px;
                position: relative;

                & span {
                    &:after {
                        position: absolute;
                        content: "";
                        bottom: 13px;
                        width: 70%;
                        left: 15%;
                        right: 0;
                        border-bottom: 1px solid;
                        transform: scaleX(0);
                        transform-origin: left center;
                        transition: transform .25s ease-out;
                        will-change: transform;
                        pointer-events: none;
                    }

                    &:hover {
                        &:after {
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }
    }


    &.ford {
        font-family: Source Sans Pro, sans-serif !important;

        .modal-submit {
            border-radius: 3px;
            background-color: #31b3f6 !important;
            border-color: #31b3f6 !important;
            color: #fff !important;
            caret-color: #fff !important;

            &:hover {
                background-color: rgb(45, 150, 205) !important;
                border-color: rgb(45, 150, 205) !important;
            }
        }

        .widget {


            &__title {
                font-size: 28px !important;
                line-height: 32px;

                @media (min-width: 1025px) {
                    font-size: 38px;
                    line-height: 48px;
                }
            }

            &__subtitle {
                font-size: 16px;
                line-height: 23px;
            }

            &__button {
                border-radius: 3px;
                background-color: #31b3f6 !important;
                border-color: #31b3f6 !important;
                color: #fff !important;
                caret-color: #fff !important;

                &:hover {
                    background-color: rgb(45, 150, 205) !important;
                    border-color: rgb(45, 150, 205) !important;
                }
            }

            &__frame {
                border: 2px solid #31b3f6;
            }
        }
    }

    &.hyundai {
        font-family: HuindaySansHead, sans-serif !important;

        .modal-submit {
            background-color: #002c5f !important;
            border-color: #002c5f !important;
            color: #fff !important;
            caret-color: #fff !important;
            border-radius: 3px;

            &:hover {
                background-color: #00aad2 !important;
                border-color: #00aad2 !important;
            }
        }

        .widget {

            &__title {
                font-size: 28px !important;
                line-height: 32px;

                @media (min-width: 1025px) {
                    font-size: 38px;
                    line-height: 48px;
                }
            }

            &__subtitle {
                font-size: 16px;
                line-height: 23px;
            }

            &__button {
                background-color: #002c5f !important;
                border-color: #002c5f !important;
                color: #fff !important;
                caret-color: #fff !important;
                border-radius: 3px;

                &:hover {
                    background-color: #00aad2 !important;
                    border-color: #00aad2 !important;
                }
            }

            &__frame {
                border: 2px solid #002c5f;
            }
        }
    }

    &__frame {
        width: 180px;
        height: 180px;
        border: 2px solid #000;
        margin: -188px auto 0;
        border-radius: 5px;
    }

    &__title {
        font-weight: 700;
        font-size: 38px;
        line-height: 46px;
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
        text-transform: uppercase;
    }

    &__descr {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        max-width: 700px;
        margin: 15px auto 0;
    }

    &__list.swiper {
        margin: 30px auto;
        max-width: 910px;
        position: relative;

        &:after {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            pointer-events: none;
            background: linear-gradient(rgba(255,255,255,0));
        }
    }

    .swiper-wrapper {
        margin: 15px 0;
    }

    &__item.swiper-slide {
        display: flex;
        justify-content: center;
        //position: relative;
        //width: 160px !important;
        //height: 160px;
        //margin: 15px;

        /*&:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            background: radial-gradient(50% 50% at 50% 50%, hsla(0, 0%, 100%, 0.08) 0, hsla(0, 0%, 100%, 0) 100%);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: opacity 0.2s 3s;
            opacity: 1;
        }

        &:after {
            position: absolute;
            content: "";
            width: 190px;
            height: 190px;
            border: 2px solid #8e1c2e;
            top: 5px;
            left: 50%;
            transform: translateX(-50%);
        }*/
    }

    &__item-content {
        border: 1px solid #cccccc;
        position: relative;
        width: 160px;
        height: 160px;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__image {
        width: 50px;
        height: 50px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: end;
        align-items: flex-end;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 0 auto 20px;

        & img {
            display: block;
            width: 100%;
        }
    }

    &__subtitle {
        text-align: center;
        font-size: 16px;
        line-height: 22px;
        padding: 0 5px;
    }

    &__button {
        position: relative;
        z-index: 3;
        cursor: pointer;
        background-color: #000;
        text-align: center;
        font-size: 13px;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        padding: 18px 44px;
        font-weight: 700;
        margin: 0 auto;
        display: block;
        border: none;
        outline: none;
        transition: all .2s ease-in-out;

        &:disabled {
            opacity: 0.3;
        }
    }

    .dialog-enter-active, .dialog-leave-active {
        transition: opacity .3s
    }

    .dialog-enter, .dialog-leave-to {
        opacity: 0;
    }

    @keyframes animSlide {
        from {
            opacity: 1;
        }
        10% {
            opacity: 0;
        }
        20% {
            opacity: 1;
        }
        30% {
            opacity: 0;
        }
        40% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        60% {
            opacity: 1;
        }
        to {
            opacity: 1;
        }
    }


}

</style>
